import React from "react";
import check from "../media/img/check.png";

function FAQ() {
    return (
        <div class="xl:mx-[15%] m-4 mt-10 block rounded-lg bg-white p-6 dark:bg-neutral-800 dark:shadow-black/20">
            <div class="text-lg font-sans font-bold tracking-wider mb-3">
                Najczęściej zadawane pytania
            </div>
        <div class="bg-white-700">
            
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingOne">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white-700 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    Jakie są koszty związane z importem samochodu z Japonii?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseOne"
                class="!visible border-0"
                data-twe-collapse-item
                data-twe-collapse-show
                aria-labelledby="flush-headingOne"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4">
                Koszty obejmują:
                <ul>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Cenę zakupu pojazdu.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Koszt transportu morskiego (fracht).</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Cło i podatek VAT.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Opłaty portowe i manipulacyjne.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Koszty homologacji i adaptacji pojazdu do europejskich standardów.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Opłaty za rejestrację i ubezpieczenie.</div></li>
                </ul>
                </div>
                </div>
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingTwo">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo">
                    Jak wygląda proces importu samochodu z Japonii?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseTwo"
                class="!visible hidden border-0"
                data-twe-collapse-item
                aria-labelledby="flush-headingTwo"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4">
                Proces składa się z kilku etapów:
                    <ul class="list-decimal list-inside space-y-2">
                        <li>Wybór i zakup pojazdu.</li>
                        <li>Transport morski do portu docelowego.</li>
                        <li>Odprawa celna.</li>
                        <li>Homologacja pojazdu.</li>
                        <li>Rejestracja i ubezpieczenie.</li>
                    </ul>
                </div>
                </div>
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingThree">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree">
                    Jak długo trwa transport samochodu z Japonii do Polski?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseThree"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingThree"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4">
                Transport morski zwykle trwa od 4 do 8 tygodni, w zależności od portu załadunku i rozładunku.
                </div>
                </div>
                
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingFour">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour">
                    Jakie dokumenty są potrzebne do importu samochodu?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseFour"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingFour"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4">
                Wymagane dokumenty to:
                <ul>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Faktura zakupu.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Certyfikat eksportowy (Export Certificate).</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Dokumenty frachtowe (Bill of Lading).</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Dokumenty celne.</div></li>
                    <li><div class="flex gap-4 mt-2"><img src={check} class="size-5"/>Dokumenty homologacyjne.</div></li>
                </ul>
                </div>
                </div>
                
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingFive">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive">
                    Jakie są wymagania dotyczące homologacji pojazdu?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseFive"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingFive"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4 text-justify">
                Homologacja polega na dostosowaniu pojazdu do norm europejskich, co może
                obejmować m.in. zmianę oświetlenia, dostosowanie prędkościomierza do km/h oraz
                inne modyfikacje techniczne. W Polsce homologacją zajmują się specjalistyczne stacje
                diagnostyczne.
                </div>
                </div>
                
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingSix">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix">
                    Czy są jakieś ograniczenia dotyczące wieku pojazdu importowanego z Japonii?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseSix"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingSix"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4 text-justify">
                Nie ma ścisłych ograniczeń co do wieku pojazdu, ale starsze pojazdy mogą wymagać
                większych nakładów na dostosowanie do europejskich norm oraz mogą być objęte
                wyższymi stawkami celnymi i podatkowymi.
                </div>
                </div>
                
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingSeven">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven">
                    Czy mogę sprowadzić samochód z kierownicą po prawej stronie (RHD)?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseSeven"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingSeven"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4 text-justify">
                Tak, ale w Polsce takie samochody muszą przejść adaptację, aby mogły zostać
                zarejestrowane. Adaptacja zwykle obejmuje m.in. zmianę układu świateł, lusterka oraz
                prędkościomierza.
                </div>
                </div>
                
            </div>
            <div
                class="rounded-none bg-white-700">
                <h2 class="mb-0 font-semibold" id="flush-headingEight">
                <button
                    class="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-red-700 [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                    type="button"
                    data-twe-collapse-init
                    data-twe-collapse-collapsed
                    data-twe-target="#flush-collapseEight"
                    aria-expanded="false"
                    aria-controls="flush-collapseEight">
                    Czy istnieje możliwość finansowania samochodu ściągniętego z Japonii, Dubaju czy USA leasingiem bądź pożyczką?
                    <span
                    class="-me-1 ms-auto [&>svg]:stroke-gray-700 h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor">
                        <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    </span>
                </button>
                </h2>
                <div
                id="flush-collapseEight"
                class="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="flush-headingEight"
                data-twe-parent="#accordionFlushExample">
                <div class="py-4 text-justify">
                Oczywiście, że tak. Najpierw jednak musicie Państwo pokryć całkowite koszty importu samochodu ze swojej kieszeni. Niestety nie ma na rynku instytucji leasingowej oraz bankowej która płaciłaby bezpośrednio za granicę za wylicytowany przez Państwa przedmiot. 

                Procedura finansowania leasingiem bądź pożyczką wygląda w przypadku samochodów importowanych następująco:
                <ul class="list-decimal list-inside my-2 pl-3">
                    <li>Samochód znajduje się już na terenie PL z załatwionymi wszystkimi formalnościami potrzebnymi do rejestracji auta na terenie PL</li>
                    <li>Następnie składamy wniosek do jednej bądź kilku spółek leasingowych/banków o refinansowanie kosztów zakupu samochodu ( tzw. leasing zwrotny/pożyczka zwrotna ). Państwo jako klient są w tym wypadku zarówno „sprzedającym” jak i klientem leasingowym/pożyczkowym</li>
                    <li>Firma leasingowa/bank weryfikuje Państwa zdolność leasingową/kredytową w międzyczasie sprawdzając również sam przedmiot finansowania, w tym wypadku samochód</li>
                    <li>Po wydaniu pozytywnej decyzji dotyczącej finansowania następuję podpisanie umowy leasingowej/kredytowej ( w zależności od instytucji może być wymagana wycena rzeczoznawcy )</li>
                    <li>Następnie samochód jest rejestrowany ( w przypadku leasingu na firmę finansującą, natomiast w przypadku pożyczki na Państwa jako klienta )</li>
                    <li>Po rejestracji instytucja finansująca przelewa na Państwa konto kwotę z FV wystawionej przez Państwa pomniejszoną o udział własny</li>
                </ul>

                Wszystkie z wyżej wymienionych formalności załatwiamy za Państwa, od momentu złożenia wniosku o finansowanie po podpisanie umowy finansowania.  Jako Carsproject mamy podpisane umowy z czołowymi spółkami leasingowymi na terenie PL jak i również bankami zajmującymi się finansowaniem samochodów osobowych
                </div>
                </div>
                
            </div>
            </div>
            
        </div>
    );
}

export default FAQ;