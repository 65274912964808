import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProcedureStep from "../components/ProcedureStep";
import carIcon from "../media/img/carIcon.png";
import inspectionIcon from "../media/img/inspectionIcon.png";
import dealIcon from "../media/img/dealIcon.png";
import paymentIcon from "../media/img/paymentIcon.png";
import logisticsIcon from "../media/img/logisticsIcon.png";
import taxesIcon from "../media/img/taxesIcon.png";
import registrationIcon from "../media/img/registrationIcon.png"
import deliveryIcon from "../media/img/deliveryIcon.png"
import extraIcon from "../media/img/extraIcon.png";
import ContactForm from "../components/ContactForm";

import { Image } from 'primereact/image';

function ProcedurePage() {
    return <div class="relative h-fit min-h-screen">
        <Navbar mb="mb-48"/>
        <div class="xl:mx-[13%]">
            <div class="font-sans text-gray-700 p-5 my-10 lg:text-lg lg:tracking-wider text-center">
                Sprowadzenie samochodu z Japonii może być skomplikowanym procesem, ale jesteśmy
                tutaj, aby Ci pomóc na każdym etapie. Oferujemy kompleksową obsługę, aby
                sprowadzić twój wymarzony samochód z Japonii do Polski. Poniżej przedstawiamy nasz
                proces importu oraz płatności:
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5 bg-gray-100">
                <ProcedureStep title="wybór samochodu" icon={carIcon} text="Przedstawimy Ci kilka propozycji spełniających twoje wymagania, wraz z pełnym opisem technicznym,
                historią serwisową i zdjęciami."
                />
                <ProcedureStep title="weryfikacja i inspekcja" icon={inspectionIcon} text="Zorganizujemy dokładną inspekcję wybranego samochodu
przez naszych japońskich partnerów. Otrzymasz szczegółowy raport techniczny i zdjęcia pojazdu."
                />
                <ProcedureStep title="zakup i negocjacje" icon={dealIcon} text="Nasi agenci w Japonii przeprowadzą negocjacje cenowe z
sprzedawcą. Po zaakceptowaniu oferty dokonujemy zakupu samochodu w
Twoim imieniu."
                />
                <ProcedureStep title="płatności" icon={paymentIcon} text="Wpłacasz zaliczkę na nasze konto. Zazwyczaj wynosi ona od 10-30%
wartości pojazdu. Po otrzymaniu wszystkich dokumentów potwierdzających
zakup, dokonujesz płatności końcowej."
                />
                <ProcedureStep title="transport i logistyka" icon={logisticsIcon} text="Organizujemy transport pojazdu do portu w Japonii. Zajmujemy się rezerwacją miejsca na statku oraz
ubezpieczeniem pojazdu na czas transportu."
                />
                <ProcedureStep title="odprawa celna i podatki" icon={taxesIcon} text="Po przybyciu samochodu do portu w Polsce/Niemczech, zajmujemy się
wszystkimi formalnościami celnymi. Opłacasz cło oraz podatek VAT (23%) lub VAT
(19%). Przekazujemy Ci komplet dokumentów niezbędnych do
odprawy celnej."
                />
                <ProcedureStep title="homologacja i rejestracja" icon={registrationIcon} text="Przeprowadzamy proces homologacji, aby samochód spełniał
europejskie normy techniczne. Organizujemy przegląd techniczny pojazdu. Pomagamy w rejestracji samochodu w lokalnym urzędzie komunikacji."
                />
                <ProcedureStep title="odbiór samochodu" icon={deliveryIcon} text="Po zakończeniu wszystkich formalności samochód jest gotowy
do odbioru. Możemy również zorganizować transport samochodu
bezpośrednio pod Twój adres."
                />
                <ProcedureStep title="dodatkowe usługi" icon={extraIcon} text="Oferujemy doradztwo na każdym etapie procesu importu. Zapewniamy wsparcie techniczne i serwisowe po przybyciu
pojazdu do Polski."
                />

            </div>
        </div>
        <div class="text-gray-700 font-sans p-5 my-10 xl:mx-[13%] lg:text-lg lg:tracking-wider text-center">
        Jesteśmy tutaj, aby zapewnić Ci pełen spokój i pewność, że cały proces importu
        samochodu z Japonii przebiegnie sprawnie i bezproblemowo. Skontaktuj się z nami, aby
        rozpocząć poszukiwania Twojego wymarzonego samochodu!
        </div>
        <ContactForm />
        <Footer />
        {/* #F6F6F0 */}
    </div>
}

export default ProcedurePage;