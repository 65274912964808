import React, { useState } from "react";
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import 'primereact/resources/themes/lara-light-blue/theme.css'
import Tailwind from 'primereact/passthrough/tailwind';

function OfferGallery({ images }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const onPrevBtnClick = function() {
        setActiveIndex(prevIndex => {
            return prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1;
        });
    }

    const onNextBtnClick = function() {
        setActiveIndex(prevIndex => {
            return prevIndex + 1 > images.length - 1 ? 0 : prevIndex + 1;
        });
    }

    return (
        <div className="relative w-full p-2">
            <div class="relative flex justify-center">
                <div class="h-[30rem] content-center">
                <Image imageClassName="max-h-[30rem]" preview src={images[activeIndex].image}/>

                </div>
                <button onClick={onPrevBtnClick} class="absolute top-1/2 left-1 rounded-full bg-gray-950/70 px-2 py-2 hover:bg-gray-950/80">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-6 opactiy-80">
                    <path fill-rule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clip-rule="evenodd" />
                    </svg>
                </button>
            <button onClick={onNextBtnClick} class="absolute top-1/2 right-1 rounded-full bg-gray-950/70 px-2 py-2 hover:bg-gray-950/80">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-6 opacity-80">
                <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clip-rule="evenodd" />
                </svg>

            </button>
            </div>
            <div className="flex w-full justify-end">
                <div class="flex bg-gray-950/80 rounded p-1 mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-5">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clip-rule="evenodd" />
                    </svg>
                    <span class="font-sans ml-1 text-sm text-zinc-200 font-semibold">{activeIndex+1}/{images.length}</span>
                </div>
            </div>
        </div>
        
    )
}

export default OfferGallery;