import React from "react";
import carIcon from "../media/img/carIcon.png";

function ProcedureStep({ icon, title, text }) {
    return (
        <div class="flex flex-col justify-center items-center rounded-lg justify-self-center size-80 bg-white">
            { icon !== '' && <img src={icon} class="size-20 mb-5"></img>}
            { title !== '' && <p class="uppercase font-sans text-gray-700 font-bold mb-2">{title}</p>}
            <p class="font-sans text-sm w-60 text-center">
                {text}
            </p>
        </div>
    );
}

export default ProcedureStep;