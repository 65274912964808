import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../media/img/logo.png";
import "../App.css";

function Navbar({ mb }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleMenu = () => {
      setIsExpanded(!isExpanded);
      console.log('menu toggled: ', isExpanded);
    };
    
    return (
        <div class={`flex xl:px-[15%] shadow-sm relative transition-all easy-in-out duration-1000 h-14 z-10 w-full pt-5 ${isExpanded ? mb : 'mb-2'}`}>
            <div class="w-full h-full">
                <Link to="/"><img src={logo} class="absolute -top-8 md:-top-12 size-32 md:size-40 grayscale brightness-50 hover:grayscale-0 hover:brightness-100"></img></Link>
            </div>
            {/* <Link to="/"><p class="text-gray-800 bg-red-700 w-full ml-5 text-xs md:text-base md:ml-10 font-sans font-bold">CarsProjectImport</p></Link> */}
            <div class ="md:flex md:w-full md:justify-end md:space-x-5 md:mr-10 xl:mr-0">
                <Link to="/kontakt"><p class="invisible md:visible text-gray-800 hover:text-gray-600" opacity-80 >Kontakt</p></Link> 
                <Link to="/procedura"><p class="invisible md:visible text-gray-800 hover:text-gray-600">Procedura</p></Link>
                <Link to="/finansowanie"><p class="invisible md:visible text-gray-800 hover:text-gray-600">Finansowanie</p></Link>
                <Link to="/aukcje"><p class="invisible md:visible text-gray-800 hover:text-gray-600">Aukcje</p></Link>
                <Link to="/oferta"><p class="invisible md:visible text-red-700 font-bold hover:text-red-600">Oferta</p></Link>
            </div>
            <div class={`grid w-full justify-items-end transition-all md:invisible md:w-0 `}>
                    <span class="[&>svg]:w-7 [&>svg]:stroke-gray-800 dark:[&>svg]:stroke-neutral-200" onClick={toggleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10 -translate-y-3 mr-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                        </svg>
                    </span>
            </div>
            <div class={`absolute text-end right-10 top-14 w-0 h-0 transition-opacity ${isExpanded ? 'delay-200 duration-500 opacity-100 w-fit h-fit' : 'opacity-0'} pt-2 font-sans tracking-wider text-lg sm:text-base md:text-base pl-3 [&_p]:pb-1 md:invisible`}>
                <Link to="/kontakt"><p class={`text-gray-800 opacity-80 ${isExpanded ? "slideInLeft" : ''}`} style={{'--delay': '200ms'}}>Kontakt</p></Link> 
                <Link to="/procedura"><p class={`text-gray-800 opacity-80 ${isExpanded ? "slideInLeft" : ''}`} style={{'--delay': '300ms'}}>Procedura</p></Link>
                <Link to="/finansowanie"><p class={`text-gray-800 opacity-80 ${isExpanded ? "slideInLeft" : ''}`} style={{'--delay': '400ms'}}>Finansowanie</p></Link>
                <Link to="/aukcje"><p class={`text-gray-800 opacity-80 ${isExpanded ? "slideInLeft" : ''}`} style={{'--delay': '500ms'}}>Aukcje</p></Link>
                <Link to="/oferta"><p class={`text-red-700 font-bold ${isExpanded ? "slideInLeft" : ''}`} style={{'--delay': '600ms'}}>Oferta</p></Link>
            </div>
        </div>
            // isExpanded ? 
            // <div class='relative h-20 z-10 text-white mb-20'>
            //     <div class="transition transform easy-in-out duration-5000 relative top-8 left-5 scale-x-100 bg-black" onClick={toggleMenu}>
            //         <span class="[&>svg]:w-7 [&>svg]:stroke-blue-700 dark:[&>svg]:stroke-neutral-200">
            //             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
            //             <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
            //             </svg>
            //         </span>
            //         <p>1</p>
            //         <p>1</p>
            //         <p>1</p>
            //         <p>1</p>
            //     </div>
            //     <img src={logo} class="absolute right-0 -top-10 size-48"></img>
            // </div> :
            // <div class="relative h-20 z-10 text-white">
            //     <div class="absolute top-8 left-5 bg-green" onClick={toggleMenu}>
            //         <span class="[&>svg]:w-7 [&>svg]:stroke-blue-700 dark:[&>svg]:stroke-neutral-200">
            //             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
            //             <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
            //             </svg>
            //         </span>
            //     </div>
            //     <img src={logo} class="absolute right-0 -top-10 size-48"></img>
            // </div>
    );
}

export default Navbar;