import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import ProcedureStep from "../components/ProcedureStep";
import registrationIcon from "../media/img/registrationIcon.png";
import signingIcon from "../media/img/signingIcon.png";
import paymentIcon from "../media/img/paymentIcon.png";
import applicationIcon from "../media/img/applicationIcon.png";
import leasingIcon from "../media/img/leasingIcon.png";
import auctionIcon from "../media/img/auctionIcon.png";

function Financing() {
    return (
        <div class="relative h-fit min-h-screen">
        <Navbar mb="mb-48"/>
        <div class="xl:mx-[13%]">
            <div class="font-sans text-gray-700 p-5 my-10 lg:text-lg lg:tracking-wider text-center">
            Sprowadzanie pojazdu z Japonii może być ekscytującym, ale i wymagającym przedsięwzięciem. Aby cały proces przebiegał sprawnie i bezproblemowo, warto skorzystać z profesjonalnej pomocy w załatwieniu finansowania. Nasza oferta jest skierowana do wszystkich, którzy marzą o posiadaniu unikalnego, wysokiej jakości pojazdu z Kraju Kwitnącej Wiśni, ale potrzebują wsparcia finansowego na każdym etapie zakupu. Zapewniamy kompleksową obsługę, od doradztwa po finalizację umowy, gwarantując przy tym atrakcyjne warunki kredytowe dostosowane do indywidualnych potrzeb każdego klienta. Dzięki naszemu doświadczeniu i szerokim kontaktom w branży motoryzacyjnej oraz finansowej, możemy zaoferować rozwiązania, które uczynią Twój zakup prostym i bezpiecznym.
            <br />
            <br />
            <br />
            
            Jak wygląda ścieżka w przypadku takiego rozwiązania ?

            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5 bg-gray-100">
            <ProcedureStep title="" icon={applicationIcon} text="Składamy wniosek do banku o przyznanie kredytu w wysokości jakiej Państwo chcecie zakupić pojazd."
            />
            <ProcedureStep title="" icon={signingIcon} text="Po uzyskaniu zgody na finansowanie następuje podpisanie umowy kredytowej z naszym pracownikiem"
            />
            <ProcedureStep title="" icon={paymentIcon} text="Wypłata środków na Państwa konto bankowe"
            />
            <ProcedureStep title="" icon={auctionIcon} text="Licytacja samochodu którym są Państwo zainteresowani"
            />
            <ProcedureStep title="" icon={registrationIcon} text="Po wygranej licytacji i przypłynięciu samochodu na teren PL Państwo rejestrujecie na siebie pojazd."
            />
            <ProcedureStep title="" icon={leasingIcon} text="Dostarczenie do banku dowodu rejestracyjnego celem ustanowienia przewłaszczenia oraz polisy AC z cesją na instytucję finansującą."
            />
            </div>
        </div>
        <div class="text-gray-700 font-sans p-5 my-10 xl:mx-[13%] lg:text-lg lg:tracking-wider text-center">
        Jesteśmy tutaj, aby zapewnić Ci pełen spokój i pewność, że cały proces importu
        samochodu z Japonii przebiegnie sprawnie i bezproblemowo. Skontaktuj się z nami, aby
        rozpocząć poszukiwania Twojego wymarzonego samochodu!
        </div>
        <ContactForm />
        <Footer />
        {/* #F6F6F0 */}
    </div>
    );
}

export default Financing;