import React, { useEffect, useRef } from "react";
import procedureBg from "../media/img/miata.jpeg"
import JapanFlag from "../media/img/japan-flag-icon.png";
import { motion, useInView, useAnimation } from "framer-motion";

function Procedure() {

    return (
        // <!--Background-->
        <section
        class="rounded-md p-6 mt-10 bg-cover bg-bottom shadow-lg md:p-12 md:text-left"
        style={{backgroundImage: `url(${procedureBg})`}}>
        <div class="flex">
            <div class="max-w-3xl">
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1 }}
                variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 }
                }}
                class="relative m-4 block rounded-lg bg-white/50 p-6 shadow-gray-700 shadow-lg dark:bg-neutral-800 dark:shadow-black/20">
                {/* <!--Testimonial--> */}
                <div class="text-2xl flex font-bold tracking-wider text-gray-700"><img src={JapanFlag} class="w-10 h-7 mr-2 shadow-primary-1"/>Import </div>
                <div class="md:flex md:flex-row">
                <div class="md:ms-6">
                    <p
                    class="mt-5 mb-6 font-light text-gray-700 dark:text-neutral-300">
                    Nic z naszej aktualnej oferty nie spełnia twoich wymagań? <span class="text-red-700 font-bold">Żaden problem!</span> Sprowadzimy twoje wymarzone auto specjalnie dla Ciebie
                    </p>
                    <p
                    class="mb-2 text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Poznaj nasz proces
                    </p>
                    <div className="flex relative h-20 mt-5">
                        <div class="absolute left-0 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">1</div> 
                        <div class="absolute -top-1 left-10 h-40 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        </div>
                    </div>
                    <div className="flex relative h-20">
                        <div class="absolute right-0 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">2</div> 
                        <div class="absolute -top-1 left-0 h-40 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        </div>
                    </div>
                    <div className="flex relative h-20">
                        <div class="absolute left-0 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">3</div> 
                        <div class="absolute -top-1 left-10 h-40 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        </div>
                    </div>
                    <div className="flex relative h-20">
                        <div class="absolute right-0 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">4</div> 
                        <div class="absolute -top-1 left-0 h-40 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        </div>
                    </div>
                </div>
                {/* <div class="grid grid-cols-4 border-collapse pt-1">
                    <div class="col-span-1 border-t-2 border-l-2 border-b-2 border-r-0 rounded-tl-full rounded-bl-full  border-neutral-200">1</div>
                    <div class="col-span-1 border-b-2 border-neutral-200">2</div>
                    <div class="col-span-2 border-b-2 w-11/12 border-neutral-200">3</div>
                    <div class="col-span-2 border-b-2 border-neutral-200">1</div>
                    <div class="col-span-1 border-b-2 border-neutral-200">2</div>
                    <div class="col-span-1 border-b-2 border-l-0 border-r-2 rounded-tr-full rounded-br-full  border-neutral-200">3</div>

                </div> */}
                {/* <div class="static">
                    <div class="flex relative mt-10 border-collapse">
                        <div class="h-10 w-10 border-2 border-r-0 rounded-tl-full rounded-bl-full border-t-neutral-200 border-l-neutral-200 border-b-neutral-200 ">
                        </div>
                        <div class="absolute -top-5 left-12 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">1</div> 
                        <div class="absolute bottom-0 left-10 w-3/4 border-b-2 border-b-neutral-200 text-white "></div>
                        <div class="absolute bottom-6 left-24 w-full text-sm">Powiedz nam czego szukasz</div>  
                    </div>
                    <div class="flex relative border-t-red h-10 w-full">
                        <div class="absolute -top-0 -mt-0.5 right-0 h-10 w-10 border-2 border-l-0 rounded-tr-full rounded-br-full border-t-neutral-200 border-r-neutral-200 border-b-neutral-200 ">
                        </div>
                        <div class="absolute -bottom-5 right-12 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">2</div> 
                        <div class="absolute -bottom-3 right-20 w-1/2">Znajdujemy auto</div> 
                    </div>
                    <div class="flex relative border-t-red h-10 w-full">
                        <div class="absolute -top-0 -mt-0.5 left-0 h-10 w-10 border-2 border-r-0 rounded-tl-full rounded-bl-full border-t-neutral-200 border-r-neutral-200 border-b-neutral-200 ">
                        </div>
                        <div class="absolute -bottom-5 left-12 flex justify-center items-center h-10 w-10 bg-gray-700 rounded-full text-white ">3</div> 
                        <div class="absolute -bottom-5 left-24 w-1/2text-white ">Umówmy się na rozmowę</div> 
                    </div>
                </div> */}
                </div>
            </motion.div>
            </div>
        </div>
        </section>
    );
}

export default Procedure;