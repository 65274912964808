import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import OffersDataView from "../components/OffersDataView";

function Offers() {
    return (
        <div class="relative h-fit min-h-screen">
            <Navbar mb="mb-48"/>
            <div class="min-h-[45rem] xl:mx-[15%] pb-40">
                <OffersDataView/>
             </div>
             <Footer />
        </div>
    );
}

export default Offers;