// Initialization for ES Users
import React from "react";
import {
    Ripple,
    initTWE,
  } from "tw-elements";
  
initTWE({ Ripple });

function CarCard({ thumbnail, title, year, price, animation }) {
    
    return (
        <div
        class={`block h-60 w-[160px] md:w-0 md:h-0 md:invisible bg-white rounded border-gray-200 border-2 shadow-xl ${animation}`}>
            <div class="overflow-hidden">
                <img
                class="h-[160px] w-[160px] md:h-0 transition-all duration-500 ease-in-out hover:scale-110"
                src={thumbnail}
                alt="offerImg"/>
            </div>
            <div class="pl-2 pt-1 items-center bg-white text-gray-700 dark:text-white w-full">
                    <p class="text-base font-semibold leading-tight truncate">{title}</p>
                    <h5 class="text-sm font-light mt-1 leading-tight">{year}</h5>
                    <p class="bottom-0 mt-1 text-lg font-bold text-red-700">
                    {price}<span class="text-xs font-light"> PLN</span>
                    </p>
            </div>
        </div>
    );
}

export default CarCard;