import React, { useRef, useState } from "react";
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import MB from '../media/img/MB.jpeg';
import contactFormBg from '../media/img/contactFormBg.jpeg';
import axios from "axios";

function ContactForm(){
    const [emailSending, setEmailSending] = useState(false);
    const [messageBox, setMessageBox] = useState('');


    const submitForm = (event) => {
        event.preventDefault();
        let name = event.target.elements.name.value;
        let email = event.target.elements.email.value;
        let subject = event.target.elements.subject.value;
        let message = event.target.elements.message.value;
        if (name === '' || email === '' || subject === '' || message === '') {
            setMessageBox(<Message severity='error' text='Prosimy wypełnić wszystkie pola formularza'/>);
            setTimeout(() => {
                setMessageBox('');
            }, 4000);
            return;
        }
        setEmailSending(true);
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post('https://formsubmit.co/ajax/biuro@cars-project.pl', {
            name: name,
            email: email,
            subject: subject,
            message: message,
        })
            .then(response => {
                setEmailSending(false);
                setMessageBox(<Message severity='success' text='Wiadomość wysłana. Postaramy się odpowiedzieć najszybciej jak to mozliwe'/>);
                setTimeout(() => {
                    setMessageBox('');
                }, 4000);
                event.target.elements.name.value = '';
                event.target.elements.email.value = '';
                event.target.elements.subject.value = '';
                event.target.elements.message.value = '';
            })
            .catch(() => {
                setEmailSending(false);
                setMessageBox(<Message severity='error' text='Ups... Coś poszło nie tak. Prosimy spróbować później lub skorzystać z danych kontaktowych podanych ponizej.'/>);
                setTimeout(() => {
                    setMessageBox('');
                }, 4000);
                event.target.elements.name.value = '';
                event.target.elements.email.value = '';
                event.target.elements.subject.value = '';
                event.target.elements.message.value = '';
            });
    }

    return (
        <section class="xl:mx-[15%] pb-48">
        <div class="h-full">
            {/* <!-- Left column container with background--> */}
            <div
            class="flex h-full flex-wrap items-center justify-center ">
            <div
                class="shrink-1 mb-6 pl-5 grow-0 overflow-hidden w-full max-h-96 lg:max-h-[60rem] basis-auto md:mb-0 md:shrink-0 lg:w-6/12 xl:w-6/12">
                <img
                src={contactFormBg}
                class="w-0 lg:w-full invisible lg:visible"
                alt="Sample image" />
                <img
                src={MB}
                class="w-full scale-110 lg:w-0 lg:hidden"
                alt="Sample image" />
            </div>

            {/* <!-- Right column container --> */}
            <div class="w-full mx-auto block max-w-md bg-white rounded-lg p-6">
                <p class="font-sans text-2xl text-gray-700 tracking-wide  font-bold">Masz pytanie?</p> 
                <p class="font-sans text-gray-500">Wypełnij formularz kontaktowy na pewno oddzwonimy!</p>
                <form class="mt-5" onSubmit={submitForm}>
                    
                    <label
                        for="name"
                        class="uppercase text-sm text-gray-700 opacity-80 font-sans tracking-wide"
                        >Imię i nazwisko
                    </label>
                    <input
                        type="text"
                        id="name"
                        class="w-full mb-4 font-sans text-gray-700 border-b border-b-gray-600 bg-transparent px-3 py-[0.32rem] leading-[1.6] focus:outline-none"
                        placeholder="" 
                    />

                    <label
                        for="email"
                        class="uppercase text-sm text-gray-700 opacity-80 font-sans tracking-wide"
                        >Adres email
                    </label>
                    <input
                        type="email"
                        id="email"
                        class="w-full mb-4 font-sans text-gray-700 border-b border-b-gray-600 bg-transparent px-3 py-[0.32rem] leading-[1.6] focus:outline-none"
                        placeholder="" 
                    />

                    <label
                        for="subject"
                        class="uppercase text-sm text-gray-700 opacity-80 font-sans tracking-wide"
                        >temat
                    </label>
                    <input
                        type="text"
                        id="subject"
                        class="w-full font-sans text-gray-700 mb-4 border-b border-b-gray-600 bg-transparent px-3 py-[0.32rem] leading-[1.6] focus:outline-none"
                        placeholder="" 
                    />

                    <label
                        for="message"
                        class="uppercase text-sm text-gray-700 opacity-80 font-sans tracking-wide"
                        >Treść
                    </label>
                    <textarea
                        class="w-full font-sans text-gray-700 mb-2  border-b border-b-gray-600 bg-transparent px-3 py-[0.32rem] leading-[1.6] focus:outline-none"
                        id="message"
                        rows="5"
                        placeholder="">
                    </textarea>

                    {/* <!--Submit button--> */}
                    <button
                    type="submit"
                    class="inline-block mt-4 w-full rounded bg-red-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3"
                    >
                    Wyślij
                    </button>
                    <div class="mt-5 w-full text-center">
                        {emailSending && <div class=""><ProgressSpinner style={{width: '50px', height: '50px'}}/><Message severity="info" text="Trwa wysyłanie wiadomości, poczekaj na potwierdzenie."/></div>}
                        {messageBox}
                    </div>
                </form>
                <div
                    class="my-8 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300 dark:before:border-neutral-500 dark:after:border-neutral-500">
                    <p
                    class="mx-4 mb-0 text-center font-sans font-semibold dark:text-white">
                    Lub
                    </p>
                </div>
                <div class="space-y-1 text-center">
                    <p class="font-sans text-3xl text-gray-700 tracking-wide  font-bold">Zadzwoń!</p> 
                    <p class="font-sans text-gray-500 text-base">+48 451 215 275</p>
                    <p class="font-sans text-gray-500 text-base">Email: biuro@cars-project.pl</p>
                    <p class="font-sans text-gray-500 text-base">Pracujemy:</p>
                    <p class="font-sans text-gray-500 text-base">Pn.-Sb godz. 8-16</p>
                </div>
            </div>
            </div>
        </div>
        </section>
    );
}

export default ContactForm;