import React from "react";
import ContactForm from "../components/ContactForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function ContactPage() {
    return (
        <div class="relative min-h-screen h-fit">
            <Navbar mb="mb-48"/>
            <div class="p-5 font-sans xl:mx-[15%] text-gray-700 lg:text-lg lg:tracking-wider text-center my-10">
            Dziękujemy za zainteresowanie naszą ofertą. Pozostajemy do twojej dyspozycji, aby odpowiedzieć na wszystkie Twoje pytania i pomóc w każdej sprawie. Nasz zespół specjalistów jest dostępny od poniedziałku do piątku, od godziny 9:00 do 17:00. Skontaktuj się z nami, korzystając z poniższego formularza kontaktowego, wysyłając e-mail na adres <span class="font-bold text-red-700">biuro@cars-project.pl</span> lub dzwoniąc pod numer telefonu <span class="font-bold text-red-700">+48 451 215 275</span>. Z niecierpliwością czekamy na wiadomość od Ciebie!
            </div>
            <ContactForm />
            <Footer />
        </div>
    )
}

export default ContactPage;