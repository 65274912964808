import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import comingSoon from "../media/img/comingSoon.png";

function Auctions() {
    return (
        <div class="relative h-fit min-h-screen">
            <Navbar mb="mb-48"/>
            <div class="flex flex-col items-center mt-10 h-fit pb-40">
                <div class="w-2/3 flex flex-col items-center rounded-3xl">
                    <img src={comingSoon} class="size-60 md:size-80"></img>
                    <p class="mt-10 font-sans tracking-wider text-base text-2xl text-center text-gray-700">Juz niedługo w tym miejscu będziecie mogli Państwo zobaczyć samochody, które są aktualnie wystawione do licytacji na aukcjach w Japonii.</p>
                </div>
            </div>
            <Footer /> 
        </div>
    );
}

export default Auctions;
