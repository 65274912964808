import React, { useRef, useState } from "react";
import CarCard from "./carCard";
import skyline from '../media/img/skylineR32.jpeg';
import supra from '../media/img/supra.jpeg';
import useGetProducts from "../services/useGetProducts";
import { useEffect, } from "react";
import { Link } from "react-router-dom";
import CarCardBig from "./carCardBig";



function NewestOffer() {
  const { isLoading, isSuccess, data } = useGetProducts();
  let [numberOfColsSmall, setNumberOfColsSmall] = useState(0);
  let [numberOfColsBig, setNumberOfColsBig] = useState(0);
  const offersContainer = useRef(null);
  const [offerSmall, setOffersSmall] = useState(null);  
  const [offerBig, setOffersBig] = useState(null);  

  useEffect(() => {
    if (!offersContainer.current) return;
    const resizeObserver = new ResizeObserver(() => {
      let oneCardWitdhSmall = 160 + 6;
      let oneCardWitdhBig = 384 + 6;
      setNumberOfColsSmall(Math.floor((offersContainer.current.offsetWidth - 10) / oneCardWitdhSmall));
      setNumberOfColsBig(Math.floor((offersContainer.current.offsetWidth - 10) / oneCardWitdhBig));
    });
    resizeObserver.observe(offersContainer.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (isLoading) {
      setOffersSmall(<div>'Offers are loading...'</div>);
      setOffersBig(<div>'Offers are loading...'</div>);
    }
    if (isSuccess) {
      setOffersSmall(parseNewestOffer(data.data).slice(0,numberOfColsSmall));
      setOffersBig(parseNewestOfferBig(data.data).slice(0,numberOfColsBig));
    }
  }, [numberOfColsSmall, numberOfColsBig, isSuccess]);

  function parseNewestOfferBig(data) {
    if (!data.length) {
      return [<p class="uppercase font-sans tracking-wider font-bold text-gray-700 text-xl">Nowe auta juz niedługo w ofercie...</p>]
    } 
    return [ 
      <Link to={"oferta/"+String(data[0].id)}>
        <CarCardBig thumbnail={data[0].offer_galleries[0].image} title={data[0].title} year={data[0].car.year} price='199 999' animation='slideInLeft' car={data[0].car}/>
      </Link>,
      <Link to={"oferta/"+String(data[1].id)}>
        <CarCardBig thumbnail={data[1].offer_galleries[0].image} title={data[1].title} year={data[1].car.year} price='199 999' animation='slideInLeft' car={data[1].car}/>
      </Link>,
      <Link to={"oferta/"+String(data[2].id)}>
        <CarCardBig thumbnail={data[2].offer_galleries[0].image} title={data[2].title} year={data[2].car.year} price='199 999' animation='slideInLeft' car={data[2].car}/>
      </Link>,
      <Link to={"oferta/"+String(data[3].id)}>
        <CarCardBig thumbnail={data[3].offer_galleries[0].image} title={data[3].title} year={data[3].car.year} price='199 999' animation='slideInLeft' car={data[3].car}/>
      </Link>,
      <Link to={"oferta/"+String(data[4].id)}>
        <CarCardBig thumbnail={data[4].offer_galleries[0].image} title={data[4].title} year={data[4].car.year} price='199 999' animation='slideInLeft' car={data[4].car}/>
      </Link>,

    ];
  };
  
  function parseNewestOffer(data) {
    if (!data.length) {
      return [<p class="uppercase font-sans tracking-wider font-bold text-gray-700 text-xl">Nowe auta juz niedługo w ofercie...</p>]
    } 
    return [
      <Link to={"oferta/"+String(data[0].id)}>
        <CarCard thumbnail={data[0].offer_galleries[0].image} title={data[0].title} year={data[0].car.year} price='199 999' animation='slideInLeft'/>
      </Link>,
      <Link to={"oferta/"+String(data[1].id)}>
        <CarCard thumbnail={data[1].offer_galleries[0].image} title={data[1].title} year={data[1].car.year} price='249 999' animation='slideInRight'/>
      </Link>,
      <Link to={"oferta/"+String(data[2].id)}>
        <CarCard thumbnail={data[2].offer_galleries[0].image} title={data[2].title} year={data[2].car.year} price='249 999' animation='slideInRight'/>
      </Link>,
      <Link to={"oferta/"+String(data[3].id)}>
        <CarCard thumbnail={data[3].offer_galleries[0].image} title={data[3].title} year={data[3].car.year} price='249 999' animation='slideInRight'/>
      </Link>,
      <Link to={"oferta/"+String(data[4].id)}>
        <CarCard thumbnail={data[4].offer_galleries[0].image} title={data[4].title} year={data[4].car.year} price='249 999' animation='slideInRight'/>
      </Link>,
      

    ];
  }

  return (
    <div ref={offersContainer} class="w-full h-60 mb-20 md:h-fit md:mb-5">
      <div class="relative -top-1 bg-white font-sans slideInLeft">
        <div class="w-fit ml-5 mt-5 lg:mt-10 lg:ml-[20%] lg:mb-5">
          <p class="text-gray-700 font-bold font-sans text-xl lg:text-3xl tracking-wider">Najnowsze w ofercie</p>
        </div>
      </div>
      <div class="relative ml-5 mt-2 mr-5">
        <div class="flex justify-around w-full md:w-0 visible md:invisible">
          {offerSmall}
        </div>
        <div class="flex justify-around w-0 md:w-full invisible md:visible">
          {offerBig}
        </div>
      </div>
    </div>
  );
}

export default NewestOffer;