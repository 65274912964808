import { useQuery } from "@tanstack/react-query";
import axios from "axios";


function useGetProducts(){
    const { isLoading, isSuccess, data } = useQuery({
      queryKey: ["products"],
      queryFn: getProducts,

    }
    );

    async function getProducts() {
      const response = await axios.get('/products/');
      return response;
    }

    return { isLoading, isSuccess, data };

}
export default useGetProducts;
