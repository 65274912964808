import React from "react";
import roadIcon from "../media/icons/1.svg";
import gearBoxIcon from "../media/icons/2.svg";
import gasolineIcon from "../media/icons/3.svg";
import yearIcon from "../media/icons/4.svg";
import intWithThousandsSeparator from './utils';
import {
    Ripple,
    initTWE,
  } from "tw-elements";
  
initTWE({ Ripple });


function CarCardBig({ thumbnail, title, year, price, animation, car }) {
    
    return (
        <div
        class={`block w-0 h-0 invisible md:h-fit md:w-96 md:visible bg-white h-fit rounded border-gray-200 border-2 shadow-xl ${animation}`}>
            <div class="overflow-hidden">
                <img
                class="size-96 transition-all duration-500 ease-in-out hover:scale-110"
                src={thumbnail}
                alt="offerImg"/>
            </div>
            <div class="pl-2 pt-1 items-center bg-white text-gray-700 dark:text-white">
                <p class="text-base font-semibold leading-tight truncate">{title}</p>
                <div class='flex space-x-2'>
                    <div class="flex py-2 text-xs"><img class="size-4 mr-2" src={yearIcon}></img><p>{car.year}</p></div>
                    <div class="flex py-2 text-xs"><img class="stroke-2 stroke- size-4 mr-2" src={roadIcon}></img>{intWithThousandsSeparator(car.mileage) + ' km'}</div>
                    <div class="flex py-2 text-xs"><img class="size-4 mr-2" src={gearBoxIcon}></img><p class="truncate max-w-20">{car.gearbox}</p></div>
                    <div class="flex py-2 text-xs"><img class="stroke-2 stroke- size-4 mr-2" src={gasolineIcon}></img><p class="max-w-20 truncate">{car.gasoline}</p></div>
                </div>
                <p class="bottom-0 text-lg font-bold text-red-700">
                {price}<span class="text-xs font-light"> PLN</span>
                </p>
            </div>
        </div>
    );
}

export default CarCardBig;
