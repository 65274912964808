import React from "react";
import Navbar from "../components/Navbar";
import CTA from "../components/CTA";
import NewestOffer from "../components/NewestOffer";
import Procedure from "../components/Procedure";
import FAQ from "../components/FAQ";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Procedure2 from "../components/Procedure2";

function Home() {

    return (
        <div class="relative">
            <Navbar mb="mb-48" />
            <CTA />
            <NewestOffer />
            <Procedure2 />
            <FAQ />
            <ContactForm />
            <Footer />
        </div>

    );
}

export default Home;