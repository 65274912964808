import React from "react";
import roadIcon from "../media/icons/1.svg";
import gearBoxIcon from "../media/icons/2.svg";
import gasolineIcon from "../media/icons/3.svg";
import yearIcon from "../media/icons/4.svg";
import intWithThousandsSeparator from './utils';



function OfferCard({product}) {
    console.log(typeof product.price);
    return (
        <div
        class={'w-full h-fit md:px-10 xl:pr-10 md:h-60 mb-3 bg-white rounded shadow-lg dark:bg-surface-dark'}>
            <div class='flex relative w-full'>
                <a href="#!">
                    <img
                    class="mt-5 m-2 rounded size-40 min-w-40 md:size-52 md:min-w-52"
                    src={product.offer_galleries[0].image}
                    alt="" />
                </a>
                <div class=" pt-5 bg-white text-gray-700 dark:text-white">
                    <h5 class="text-base font-semibold leading-tight w-full sm:w-fit max-w-80 sm:max-w-[18rem] md:max-w-[18rem] lg:max-w-[25rem] xl:max-w-[30rem] truncate">{product.title}</h5>
                    <div class='columns-2 mt-2 mb-2 w-fit'>
                        <div class="flex py-2 text-sm"><img class="size-4 mr-2" src={yearIcon}></img><p class="w-20 sm:w-40 truncate">{product.car.year}</p></div>
                        <div class="flex py-2 text-sm"><img class="stroke-2 stroke- size-4 mr-2" src={roadIcon}></img><p class="w-20 sm:w-40 truncate">{intWithThousandsSeparator(product.car.mileage) + ' km'}</p></div>
                        <div class="flex py-2 text-sm"><img class="size-4 mr-2" src={gearBoxIcon}></img><p class="w-20 sm:w-40 truncate">{product.car.gearbox}</p></div>
                        <div class="flex py-2 text-sm"><img class="stroke-2 stroke- size-4 mr-2" src={gasolineIcon}></img><p class="w-20 sm:w-40 truncate">{product.car.gasoline}</p></div>
                        
                    </div>
                    <div class="w-0 md:w-[30rem] md:my-5 truncate">
                        {product.description}
                    </div>
                    <div class="absolute right-5 sm:top-5 sm:right-5 ">
                        <p class="text-lg font-bold text-red-700">
                            {intWithThousandsSeparator(product.price)}<span class="text-xs font-light"> PLN</span>
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default OfferCard;