import React, { useState } from "react";
import procedureBg from "../media/img/miata.jpeg";
import japanFlag from "../media/img/japan-flag-icon.png";

function Procedure2() {
    const [someKey, setSomeKey] = useState(Math.random());
    const steps = [
        {
            id: 1,
            title: 'Wybór samochodu',
            description: "Skontaktuj się z nami, aby omówić swoje preferencje dotyczące modelu, rocznika, przebiegu i budżetu. Przeszukamy japońskie aukcje samochodowe oraz oferty dealerów, aby znaleźć pojazd spełniający Twoje wymagania. Przedstawimy Ci kilka propozycji z pełnym opisem technicznym, historią serwisową i zdjęciami.",
            color: '#374151'
        },
        {
            id: 2,
            title: 'Weryfikacja i Inspekcja',
            description: "Zorganizujemy dokładną inspekcję wybranego samochodu przez naszych japońskich partnerów. Otrzymasz szczegółowy raport techniczny i zdjęcia pojazdu.",
            color: 'white'
        },
        {
            id: 3,
            title: 'Zakup i Negocjacje',
            description: "Nasi agenci w Japonii przeprowadzą negocjacje cenowe z sprzedawcą. Po zaakceptowaniu oferty dokonujemy zakupu samochodu w Twoim imieniu.",
            color: 'white'
        },
        {
            id: 4,
            title: 'Płatności',
            description: "Wpłacasz zaliczkę na nasze konto. Zazwyczaj wynosi ona od 10-30% wartości pojazdu. Po otrzymaniu wszystkich dokumentów potwierdzających zakup, dokonujesz płatności końcowej.",
            color: 'white'
        },
        {
            id: 5,
            title: 'Transport i Logistyka',
            description: "Organizujemy transport pojazdu do portu w Japonii. Zajmujemy się rezerwacją miejsca na statku oraz ubezpieczeniem pojazdu na czas transportu.",
            color: 'white'
        },
        {
            id: 6,
            title: 'Odprawa Celna i Podatki',
            description: "Po przybyciu samochodu do portu w Polsce/Niemczech, zajmujemy się wszystkimi formalnościami celnymi. Opłacasz cło oraz podatek VAT (23%) lub VAT (19%). Przekazujemy Ci komplet dokumentów niezbędnych do odprawy celnej.",
            color: 'white'
        },
        {
            id: 7,
            title: 'Homologacja i Rejestracja',
            description: "Przeprowadzamy proces homologacji, aby samochód spełniał europejskie normy techniczne. Organizujemy przegląd techniczny pojazdu. Pomagamy w rejestracji samochodu w lokalnym urzędzie komunikacji.",
            color: 'white'
        },
        {
            id: 8,
            title: 'Odbiór samochodu',
            description: "Po zakończeniu wszystkich formalności samochód jest gotowy do odbioru. Możemy również zorganizować transport samochodu bezpośrednio pod Twój adres.",
            color: 'white'
        }
    ]
    const [activeStep, setActiveStep] = useState(steps[0]);

    const onStepClick = (event) => {
        console.log(event.target.id);
        setSomeKey(Math.random());
        let step = steps.find(s => s.id == event.target.id);
        setActiveStep(step);
    };

    return (
        <div class="mt-10 xl:mx-[15%] md:pl-[5%] bg-gradient-to-b h-fit from-white to-[#B4B4B1] to-50% to-gray-100 ">
            <div class="ml-5 pt-5">
                <p class="font-sans font-bold text-3xl mb-5 text-gray-700 tracking-wider ml-2">
                    Import z Japonii
                </p>
            </div>
            <div class="relative h-[50rem] md:h-[40rem] w-full">
                <div class="absolute right-[16%] md:right-[40%] top-40 text-xl md:text-4xl font-sans tracking-wider font-bold uppercase text-[#B4B4B1] opacity-50 md:opacity-80">
                    procedura
                </div>
                <div id="1" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%] top-[26%] md:top-[32%]">
                    Wybór samochodu
                </div>
                <div id="2" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[31%] md:top-[39%]">
                    Weryfikacja i Inspekcja
                </div>
                <div id="3" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[36%] md:top-[46%]">
                    Zakup i Negocjacje
                </div>
                <div id="4" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[41%] md:top-[53%]">
                    Płatności
                </div>
                <div id="5" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[46%] md:top-[60%]">
                    Transport i Logistyka
                </div>
                <div id="6" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[51%] md:top-[67%]">
                    Odprawa Celna i Podatki
                </div>
                <div id="7" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[56%] md:top-[74%]">
                    Homologacja i Rejestracja
                </div>
                <div id="8" onClick={onStepClick} class="transition-all hover:-translate-x-5 z-10 cursor-pointer absolute uppercase font-sans mt-2 tracking-wider right-[20%] md:right-[15%]  top-[61%] md:top-[81%]">
                    Odbiór Samochodu
                </div>
                <div class="ml-5 min-w-40 w-[22%] h-40 pt-5">
                    <svg class="" width="100%" height="210" fill='white' xmlns="http://www.w3.org/2000/svg">
                        <rect x="0" width="100%" height="210" fill="white"></rect>
                        <circle class="stroke-red-700 fill-red-700" id='1' cx="50%" cy="50%" r="25%" stroke-width="3" fill='red'/>
                    </svg>   
                </div>
                <svg class="absolute top-0 h-full w-full stroke-gray-700 overflow-visible">
                    <line x1="20" y1="0%" x2="65%" y2="0%" stroke-width="3"></line>
                    <line x1="64.7%" y1="0%" x2="75%" y2="10%" stroke-width="3"></line>
                    <line x1="74.7%" y1="10%" x2="85%" y2="10%" stroke-width="3"></line>
                    <line x1="84.7%" y1="10%" x2="89%" y2="13%" stroke-width="3"></line>
                    <line x1="89%" y1="12.7%" x2="89%" y2="100%" stroke-width="3"></line>
                </svg>

                <button class="absolute left-[89%] top-[26%] md:top-[32%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="1" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 1 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[31%] md:top-[39%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="2" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 2 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[36%] md:top-[46%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="3" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 3 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[41%] md:top-[53%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="4" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 4 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[46%] md:top-[60%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="5" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 5 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[51%] md:top-[67%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="6" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 6 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[56%] md:top-[74%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="7" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 7 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <button class="absolute left-[89%] top-[61%] md:top-[81%] size-10 justify-center">
                    <svg class="w-full h-full stroke-gray-700 -translate-x-5">
                        <circle id="8" cx="50%" cy="50%" r="25%" stroke-width="3" onClick={onStepClick} fill={activeStep.id == 8 ? '#374151' : 'white'}/>
                    </svg>
                </button>
                <div class="absolute top-[68%] w-4/5 md:top-[50%] md:w-[35%]">
                    <div class="ml-5">
                        <p class="font-sans font-bold text-xl text-gray-700 tracking-wider ">
                            {activeStep.title}
                        </p>
                        <p class="mt-3 font-sans text-gray-700 opacity-80">
                            {activeStep.description}
                        </p>
                    </div> 
                </div>
            </div>

        </div>
        
        // <div class="">
        //     <div class="absolute h-full w-full bg-cover bg-bottom bg-[linear-gradient(to_bottom,rgb(255,255,255),transparent_50%),url('./media/img/miata.jpeg')]">
        //     </div>
        //     <div class="relative mt-10 size-96 w-full bg-cover bg-bottom bg-no-repeat"
            
        //     >
        //     <img class="absolute top-14 left-5 w-40" src={japanFlag}/>
        //     <svg class="absolute bottom-0 w-full stroke-japanFlag overflow-visible">
        //         <line x1="32%" y1="100%" x2="70%" y2="100%" stroke-width="4"></line>
        //         <line x1="70%" y1="100%" x2="80%" y2="125%" stroke-width="4"></line>
        //         <line x1="80%" y1="125%" x2="90%" y2="125%" stroke-width="4"></line>
        //         <line x1="90%" y1="125%" x2="92%" y2="130%" stroke-width="4"></line>
        //         <line x1="92%" y1="130%" x2="92%" y2="385%" stroke-width="4"></line>
        //     </svg>
        //     </div>
        //     <div class="h-96 mb-20 bg-neutral-100/20">

        //     </div>


        // </div>
    )
}

export default Procedure2;