import React, {useEffect, useRef, useState } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import Home from './pages/Home';
import Offers from './pages/Offers';
import OfferDetails from './pages/OfferDetails';
import ProcedurePage from './pages/ProcedurePage';
import Auctions from './pages/Auctions';
import ContactPage from './pages/ContactPage';
import Financing from './pages/Financing';
import {
  Ripple,
  Collapse,
  Input,
  initTWE,
} from "tw-elements";
import landingPageImg from './media/img/bg.jpeg';


function App() {
  const toTopRef = useRef(null);

  useEffect(() => {
    initTWE({ Input, Ripple, Collapse });
    toTopRef.current.scrollIntoView();
  }, [])

  return (
    <div ref={toTopRef}>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/procedura" element={<ProcedurePage />}/>
        <Route path="/aukcje" element={<Auctions />}/>
        <Route path="/kontakt" element={<ContactPage />}/>
        <Route path="/finansowanie" element={<Financing />}/>
        <Route path="/oferta" element={<Offers />}/>
        <Route path="/oferta/:offerId" element={<OfferDetails />}/>
      </Routes>
    </div>
  );
}

export default App;
