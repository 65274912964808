import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";


function useGetProductDetails(){
    let params = useParams();
    const { isLoading, isSuccess, data } = useQuery({
      queryKey: ["productDetails", params.offerId],
      queryFn: () => getProductDetails(params.offerId),
    }
    );

    async function getProductDetails(offerId) {
      const response = await axios.get(`/products/${offerId}`);
      return response;
    }

    return { isLoading, isSuccess, data };

}
export default useGetProductDetails;
