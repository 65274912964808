import React from "react";
import { Link } from "react-router-dom";

function CTA() {
    return (
      <div class="relative xl:mx-[15%] bg-white min-h-[450px] sm:min-h-[700px] lg:min-h-[700px] h-[60vh] ">
        <div class="absolute h-full w-full bg-cover bg-bottom bg-[linear-gradient(to_bottom,rgb(255,255,255),transparent_50%),url('./media/img/landingPage.jpeg')]">
        </div>
        <div class="z-10 absolute pl-[5%] pt-5 xl:left-[5%] xl:top-[5%]">
          <p class="text-gray-800 text-3xl md:text-4xl font-sans font-semibold tracking-wider mt-5">
            Importujemy <br />auta z <span class="text-red-700">Japonii</span>  <br />
          </p>
          <div class="text-wrap w-0 h-0 invisible opacity-90 text-gray-500 sm:w-96 sm:h-fit sm:visible sm:my-3">
            <p>
              Specjalizujemy się w imporcie najlepszych aut prosto z Japonii. Oferujemy szeroki wybór modeli, atrakcyjne ceny i kompleksową obsługę, aby spełnić Twoje motoryzacyjne marzenia.
            </p>
          </div>
          <div class="absolute mt-3 flex">
            <Link to="/oferta">
              <button
                type="button"
                class="rounded border-2 border-red-700 px-7 pb-3 pt-3.5 text-xs md:text-sm text-red-700 font-medium font-sans uppercase leading-normal transition duration-150 ease-in-out hover:border-red-600 focus:border-red-600 hover:text-red-600 focus:text-red-600">
                Poznaj ofertę
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
}

export default CTA;