
import React from 'react';
import { DataView } from 'primereact/dataview';
import { Link } from "react-router-dom";
import OfferCard from './OfferCard';
import useGetProducts from '../services/useGetProducts';



export default function OffersDataView() {
    const { isLoading, isSuccess, data } = useGetProducts();

    const itemTemplate = (product, index) => {
        return (
            <Link to={String(product.id)}>
                <OfferCard product={product}/>
            </Link>
                
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return <p class="text-gray-700 font-sans uppercase font-bold text-xl text-center mt-10">nowe auta wkrótce...</p>;

        let list = items.map((product, index) => {
            return itemTemplate(product, index);
        });
        console.log(list);

        return <div className="grid grid-nogutter">{list}</div>;
    };

    return (
        <div className="w-full">
            {isLoading && 'Trwa ładowanie danych'}
            {isSuccess && <DataView 
                            value={data.data}
                            listTemplate={listTemplate} 
                            paginator 
                            rows={5} 
                            />
            }
        </div>
    )
}
        