import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import OfferGallery from "../components/OfferGallery";
import { useParams } from "react-router-dom";
import useGetProductDetails from "../services/useGetProdcutDetails";
import intWithThousandsSeparator from '../components/utils.js';
import mileageIcon from '../media/icons/1.svg';
import gearBoxIcon from '../media/icons/2.svg';
import gasolineIcon from '../media/icons/3.svg';
import yearIcon from '../media/icons/4.svg';


function OfferDetails() {
    const { isLoading, isSuccess, data } = useGetProductDetails();
    console.log(data); 
    let details = [];
    if (isSuccess) {
        for (let [k,v] of Object.entries(data.data.car)) {
            details.push(
            isSuccess && <h3 class='font-sans mt-2 text-xs'>
                <span class='font-bold mr-2  tracking-wide'>{k}:</span>{v}
            </h3>)
        }
    }

    return (
        <div class='relative text-gray-700 min-h-[100vh]'>
            <Navbar mb='mb-48'/> 
            {isLoading && 'Offer details loading ...'}
            <div class="min-h-[45rem] lg:mx-[15%] pb-40">
                {isSuccess && <OfferGallery images={data.data.offer_galleries}/>}
            {isSuccess && <div class='px-5 mt-5'>
                <h1 class='font-sans text-2xl text-gray-700 font-bold tracking-wider'>{data.data.title}</h1>
                <div class="flex space-x-2 mt-3">
                    <img class="size-4 " src={yearIcon} alt="" />
                    <span class="text-xs font-sans ml-2">{data.data.car.year}</span>
                    <img class="size-4 " src={mileageIcon} alt="" />
                    <span class="text-xs font-sans ml-2">{intWithThousandsSeparator(data.data.car.mileage)} km</span>
                    <img class="size-4 " src={gearBoxIcon} alt="" />
                    <span class="text-xs font-sans ml-2">{data.data.car.gearbox}</span>
                    <img class="size-4 " src={gasolineIcon} alt="" />
                    <span class="text-xs font-sans ml-2">{data.data.car.gasoline}</span>        
                </div>
                <h2 class='font-sans text-2xl mt-3 text-red-700 font-bold tracking-wider'>{intWithThousandsSeparator(data.data.price)}<span class="text-xs font-light"> PLN</span></h2>

                <h2 class='font-sans text-lg text-gray-700 mt-2 font-bold tracking-wider'>Opis</h2>
                <h5 class='mt-2 font-sans'>{data.data.description}</h5>
            </div>
            }

            </div>
            
            <Footer />
        </div>
    )
}
export default OfferDetails;